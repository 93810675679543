import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  divider?: boolean;
}

export const HomeSection = ({ title, children, divider = true }: Props) => {
  return (
    <>
      <section>
        <h1 className="text-center">{title}</h1>
        <div>{children}</div>
      </section>
      {divider && <div className="divider" />}
    </>
  );
};
