import type { ReactNode } from "react";
import { Hero } from "./Hero";

interface Props {
  children?: React.ReactNode;
  title: ReactNode;
  description?: ReactNode;
  imgUrl?: string;
  isLoading?: boolean;
  error?: string;
  cta?: {
    text: string;
    href: string;
  };
}

export const Layout = ({
  children,
  imgUrl,
  title,
  description,
  isLoading,
  cta,
  error,
}: Props) => {
  if (isLoading)
    return (
      <div className="mx-7 my-10 flex flex-col items-center justify-center text-center">
        <h1 className="font-bold">{title}</h1>
        <div className="loading loading-dots loading-lg" />
      </div>
    );
  return (
    <div className="mb-16">
      {error && (
        <div className="alert alert-error">
          <div className="flex-1">{error}</div>
        </div>
      )}
      {title && imgUrl && description && (
        <Hero
          title={title}
          description={description}
          imgUrl={imgUrl}
          cta={cta}
        />
      )}
      <div className="px-7">
        {!imgUrl && (
          <>
            <h1>{title}</h1>
            <Description description={description} />
          </>
        )}
        {children}
      </div>
    </div>
  );
};

const Description = ({ description }: { description: ReactNode }) => {
  if (!description) return;
  if (typeof description === "string")
    return <p className="mb-7">{description}</p>;
  return (
    <div>
      {description} <div className="mb-7" />
    </div>
  );
};
