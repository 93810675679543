import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import fs from "node:fs";
import path from "node:path";
import { OurLocation } from "~/components/Home/OurLocation";
import { Layout } from "~/components/Layout/Layout";
import { EventNames } from "~/mixpanel/EventNames";
import { trackEvent } from "~/mixpanel/trackEvent";
import { Gallery } from "../components/Home/Gallery";
import { StayConnected } from "../components/Home/StayConnected";

type Props = {
  images: string[];
};

const Home: NextPage<Props> = ({ images }: Props) => (
  <>
    <Head>
      <title>Islamic Public Library New Jersey</title>
      <meta name="description" content="Islamic Public Library New Jersey" />
      <link rel="icon" href="/favicon.ico?v=1" />
    </Head>

    <Layout
      title={
        <Image
          src="/img/iplnj-hor-banner.png"
          alt="Islamic Public Library New Jersey"
          width={375}
          height={200}
        />
      }
      description="Aiming to provide the Muslim community access to authentic reading
          materials in a comfortable, safe learning environment."
      imgUrl="/img/books-on-bookshelf.png"
      cta={{
        href: "/plans",
        text: "Become a member",
      }}
    >
      <Gallery images={images} />
      <StayConnected />
      <OurLocation />
    </Layout>
  </>
);
export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
  await trackEvent(ctx, EventNames.HOME_PAGE_VIEWED);

  const galleryDir = path.join(process.cwd(), "public/img/gallery");
  const imageFiles = await fs.promises.readdir(galleryDir);

  const images = imageFiles
    .filter((file) => /\.(jpg|jpeg|png|gif)$/i.test(file))
    .map((file) => `/img/gallery/${file}`);

  return {
    props: {
      images,
    },
  };
};

export default Home;
