import { HomeSection } from "~/components/Home/HomeSection";
import { LibraryLocation } from "../LibraryLocation";

/** Shows the location on Google Maps */
export const OurLocation = () => {
  return (
    <HomeSection title="Our Location" divider={false}>
      <LibraryLocation />
    </HomeSection>
  );
};
