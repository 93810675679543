import { SocialMedia } from "~/components/Home/SocialMedia";
import { HomeSection } from "./HomeSection";

const socialMediaLinks = [
  {
    name: "X (Twitter)",
    url: "https://x.com/Ibn_Qayyim_PLNJ",
    tagLine: "Follow us on X to stay up to date with our latest announcements.",
    imgUrl: "/img/x.svg",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/@IslamiclibraryNewarkNJ",
    tagLine: "Subscribe to our YouTube channel to watch our latest videos.",
    imgUrl: "/img/youtube.png",
  },
];

export const StayConnected = () => {
  return (
    <HomeSection title="Stay Connected">
      {socialMediaLinks.map((sm) => (
        <SocialMedia {...sm} key={sm.name} />
      ))}
    </HomeSection>
  );
};
