import Link from "next/link";
import { type ReactNode } from "react";

type Props = {
  title: ReactNode;
  description: ReactNode;
  imgUrl: string;
  cta?: {
    text: string;
    href: string;
  };
};

export const Hero = ({ title, description, imgUrl, cta }: Props) => {
  return (
    <div
      className="hero relative mb-10 bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      <div className="hero-overlay bg-black bg-opacity-70"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md py-10">
          <h1 className="font-bold text-white">{title}</h1>
          <p className="mb-8">{description}</p>
          {cta && (
            <Link href={cta.href} className="btn">
              {cta.text}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
