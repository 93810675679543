"use client";

// disable typescript since this code is recommended by react-slick... and apparently it sucks
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import {
  useEffect,
  useRef,
  useState,
  type FC,
  type MutableRefObject,
  type SetStateAction,
} from "react";
import Slider from "react-slick";
import { HomeSection } from "./HomeSection";

type Props = {
  /** intentionally optional because NextJS sometimes doesn't sent props until later */
  images?: string[];
};

export const Gallery: FC<Props> = ({ images }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1 as unknown as SetStateAction<null>);
    setNav2(sliderRef2 as unknown as SetStateAction<null>);
  }, []);

  if (!images) return null;

  return (
    <HomeSection title="Gallery">
      <p>
        The gallery showcases a collection of images featuring our the
        construction and beautiful interior of our library. We hope you enjoy
        these images!
      </p>
      <div className="slider-container">
        {/* gallery */}
        <Slider
          asNavFor={nav2 as unknown as Slider}
          ref={(slider) =>
            (sliderRef1 = slider as unknown as MutableRefObject<null>)
          }
          infinite={false}
        >
          {images.map((src) => (
            <div key={src} className="p-3">
              <div
                className="h-80 w-full cursor-pointer rounded-xl bg-cover bg-center"
                style={{ backgroundImage: `url('${src}')` }}
                onClick={() => window.open(src, "_blank")}
              />
            </div>
          ))}
        </Slider>

        {/* gallery nav */}
        <Slider
          asNavFor={nav1 as unknown as Slider}
          ref={(slider) =>
            (sliderRef2 = slider as unknown as MutableRefObject<null>)
          }
          slidesToShow={4}
          swipeToSlide
          focusOnSelect
          infinite={false}
        >
          {images.map((src) => (
            <div key={src} className="p-2">
              <div
                className="h-14 w-full rounded-md bg-cover bg-center"
                style={{ backgroundImage: `url('${src}')` }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </HomeSection>
  );
};
