export const LibraryLocation = () => (
  <div className="flex flex-col justify-center">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3023.557913517886!2d-74.1802181!3d40.7277476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2533f581c848d%3A0xb14a8def1b7ab65!2sIslamic%20Public%20Library%20-%20Newark%20N.J.!5e0!3m2!1sen!2ssa!4v1707196228081!5m2!1sen!2ssa"
      loading="lazy"
    ></iframe>
    <p>
      Located in the heart of New Jersey, we are conveniently located off the
      corner of Washington Street and Longworth Street, steps away from Masjid
      Rahmah.
    </p>
    <p>
      <a
        href="https://maps.app.goo.gl/Vg8n5KtsQPeCXB1A7"
        target="_blank"
        rel="noreferrer"
      >
        <span>495 WASHINGTON ST.</span>
        <br />
        <span>NEWARK, NJ 07102</span>
      </a>
      <br />
      <a href="tel:19734000059" target="_blank" rel="noreferrer">
        973-400-0059
      </a>
    </p>
  </div>
);
