import Image from "next/image";

type Props = {
  name: string;
  imgUrl: string;
  url: string;
  tagLine: string;
};

export const SocialMedia = ({ name, imgUrl, url, tagLine }: Props) => (
  <a
    href={url}
    target="_blank"
    className="card card-side mb-5 w-full bg-white text-black no-underline shadow-xl transition-shadow duration-300 ease-in-out hover:shadow-2xl"
    key={name}
    rel="noreferrer"
  >
    <div className="flex w-40 flex-col justify-center pl-3">
      <Image
        src={imgUrl}
        alt={name}
        width={100}
        height={100}
        className="bg-white"
      />
    </div>
    <div className="card-body p-4">
      <h2 className="card-title m-0 dark:text-black">{name}</h2>
      <span className="leading-tight">{tagLine}</span>
    </div>
  </a>
);
